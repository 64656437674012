import React, { useContext} from 'react'
import { Link } from "react-router-dom"
import { UserContext } from "../context/userContext";
import Deconnexion from './Deconnexion';
import '../css/navbar.css'

export default function Navbar() {
  const {currentUser} = useContext(UserContext)
  
  return (
    <>
    <nav className='nav'>
      <div>
      <Link to="/accueil"> <a className='navtitre'>OkClients</a></Link>
        {
          currentUser ?
          // Un utilisateur connecté
          <>
            <Deconnexion/>
            <Link to="/modification_profil"><a className='navbtn'>Modification information</a></Link>
          </>
          :
          // Pas d'utilisateur connecté
          <>
            <Link to="/inscription"><a className='navbtn'>Inscription</a></Link>
            <Link to="/connexion"><a className='navbtn'>Connexion</a></Link>
          </>
        }
      </div>
    </nav>
   

    
    </>
    
  )
}
