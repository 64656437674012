import React, { useState, useContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import Connexion from './components/Connexion'
import Deconnexion from './components/Deconnexion'
import Inscription from './components/Inscription'
import Modification_profil from './components/Modification_profil'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Accueil from './pages/Accueil'
import {UserContext} from "./context/userContext"

export default function App (){
  //state (état, données)
  const {currentUser} = useContext(UserContext)
  // comportements
  if(!currentUser) {
    console.log("Affichage");
  }
  // affichage (render)
  return (
  <>
  <Navbar />
  <Routes>
    <Route path="/accueil" element= {<Accueil/>} />
    <Route path="/inscription" element= {<Inscription/>} />
    <Route path="/connexion" element= {<Connexion/>} />
    <Route path="/deconnexion" element= {<Deconnexion/>} />
    <Route path="/modification_profil" element= {<Modification_profil/>} />
  </Routes>
  <Footer/>
  </>
  )  
}
