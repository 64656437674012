import React from 'react'
import {
  signOut 
} from "firebase/auth"
import {auth} from ".././firebase-config"

export default function Deconnexion() {
  const deconnexion = async (e) => {

    try {
      signOut(auth);
    }
    catch (err){

    }
  }
  

  return (
    <a className='navbtn' onClick={deconnexion}>Deconnexion</a >
  )
}
