import React, { useContext,useRef,useState,useEffect} from 'react'
import { UserContext } from "../context/userContext";
import { Link } from "react-router-dom"
import {db, auth, firebaseConfig} from "../firebase-config";
import { doc, setDoc,push,getFirestore,collection, addDoc,getDocs  } from "firebase/firestore";
import firebase from 'firebase/compat/app';
import Commentaire from './Commentaire';
import '../css/accueil.css'

export default function Accueil () {

      //state (état, données)
      const {currentUser} = useContext(UserContext);
      const formRef = useRef();
      const rechercheRef = useRef();
      const [users, setUsers] = useState([]);
      const usersCollectionRef = collection(db, "Commentaires");
      // comportements
      const handleForm = async (e) => {
        e.preventDefault();
        console.log("Formulaire validé",rechercheRef.current.value );
        // Je purge la recherche 
        console.log(users);
        rechercheRef.current.value = "";
        const createUser = async () => {
            await addDoc(usersCollectionRef, { name: "lol", age: 32 });
          };
        try {
            createUser();
            console.log("Bonjour ",createUser);

            const querySnapshot = await getDocs(collection(db, "Commentaires"));
            querySnapshot.forEach((doc) => {
                console.log("Bonsoir ",doc);
            });
        const docRef =  await addDoc(collection(db, "Commentaires"), {
            first: "Ada",
            last: "Lovelace",
            born: 1815
          });
          
          console.log("Document written with ID: ", docRef.id);
        
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      };
      useEffect(() => {
        const getUsers = async () => {
          const data = await getDocs(usersCollectionRef);
          
        };
        getUsers();}, []);
        console.log(users);
      

      // affichage (render)

    return (
        <div className='divaccueil'>
        {
               
            currentUser ?
                // Un utilisateur connecté 
                <>
                    <p> Bonjour {currentUser.email}</p>

                    <Commentaire/>
                </>
                :
                // Pas d'utilisateur connecté
                <>
                    <p> Bonjour et bienvenue sur OKClient </p>
                    <br/>
                    <p> Commmence l'expérience dès maintenant en te connectant </p>
                    <br/>
                    <p> Bla bla bla à écrire pour expliquer le truc </p>
                    <br/>
                    <Link to="/inscription"><button className='btnaccueil'>Inscription</button></Link>
                    <Link to="/connexion"><button className='btnaccueil'>Connexion</button></Link>
                </>
                
        }
        </div>
      
    )
}

