import React, { useContext, useRef, useState } from "react";
import {useNavigate} from "react-router-dom"
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    onAuthStateChanged
  } from "firebase/auth"
import {auth} from "../firebase-config"
import '../css/connexion.css'
export default function Connexion() {

const navigate = useNavigate();
const signIn = (email, pwd) => signInWithEmailAndPassword(auth, email, pwd)
const [validation, setValidation] = useState("");

const inputs = useRef([]);
  const addInputs = (el) => {
    if (el && !inputs.current.includes(el)) {
      inputs.current.push(el);
    }
  };
  const formRef = useRef();

  const handleForm = async (e) => {
    e.preventDefault();
    console.log(inputs);
    try {
      const cred = await signIn(
        inputs.current[0].value,
        inputs.current[1].value
      );
      // à tester
      // formRef.current.reset();
      setValidation("");
      // console.log(cred);
      navigate("/accueil");
    } catch {
      setValidation("Utilisateur incconu, l'email et/ou le mot de passe sont éronnés")
    }
  };


  return (
  <>
        <div className="div-form">
            <form className="login-form"
            ref={formRef}
            onSubmit={handleForm}>
                <label htmlFor="connexionMail">Adresse Email</label>
                <input 
                required
                ref={addInputs}
                name="email"
                id="connexionMail"
                type="email"
                 />
                <label htmlFor="connexionMotDePasse">Mot de passe</label>
                <input 
                required
                ref={addInputs}
                name="connexionMotDePasse"
                id="connexionMotDePasse"
                type="password"
                 />
                 <p id="messageErreur">{validation}</p>
                 <button id="connexionValidation" className="login-form-btn">Se connecter</button>
            </form>

        </div>
    </>
  )
}
