
import React, { useState, useContext } from 'react'
import {UserContext} from "../context/userContext"
export default function Modification_profil() {

  const {currentUser} = useContext(UserContext)
  // comportements
  if(currentUser==null) {
    console.log("Pas d'utilisateur", currentUser);
  }
  else {
    console.log("Connecté", currentUser);
  }
  

  return (
    <div>Modification_profil</div>
  )
}
