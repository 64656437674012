import React, { useContext, useRef, useState } from "react";
import { UserContext } from "../context/userContext";
import {useNavigate} from "react-router-dom"
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    onAuthStateChanged
  } from "firebase/auth"

import {auth} from "../firebase-config"
import '../css/inscription.css'

export default function Inscription() {
    //state (état, données)
    const signUp = (email, pwd) => createUserWithEmailAndPassword(auth, email, pwd);
    // comportements
    const navigate = useNavigate();

  
    const [validation, setValidation] = useState("");
  
    const inputs = useRef([])
    const addInputs = el => {
      if(el && !inputs.current.includes(el)){
        inputs.current.push(el)
      }
    }  
    const formRef = useRef();
  
    const handleForm = async (e) => {
      e.preventDefault()
  
      if((inputs.current[1].value.length || inputs.current[2].value.length) < 6) {
        setValidation("6 characters minimum")
        return;
      }
      else if(inputs.current[1].value !== inputs.current[2].value) {
        setValidation("Les 2 mots de passe sont diffrérents")
        return;
      }
  
      try {
  
        const cred = await signUp(
          inputs.current[0].value,
          inputs.current[1].value
        )
        // console.log(signUp);
        // formRef.current.reset();
        setValidation("") // Vide le message d'erreur si nécesaire
         

        navigate("/accueil") // Inscription on renvoie vers la page d'accueil
  
      } catch (err) {
        console.log (err);
        if(err.code === "auth/invalid-email") {
          setValidation("Email invalide")
        }
        
        if(err.code === "auth/email-already-in-use") {
          setValidation("Email déjà utilisé");
          // faire un renvoie vers mot de passe oublié
          // Sauvegarder l'email utiliser pour le pré-remplir sur l'autre page
        }
   
      }
  
    }
  
    // affichage (render)
  return (
    <>
        <div className="div-form">
            <form className="login-form"
            ref={formRef}
            onSubmit={handleForm}>
                <label htmlFor="inscriptionMail">Adresse Email</label>
                <input 
                required
                ref={addInputs}
                name="email"
                id="inscriptionMail"
                type="email"
                 />
                <label htmlFor="inscriptionMotDePasse">Mot de passe</label>
                <input 
                required
                ref={addInputs}
                name="inscriptionMotDePasse"
                id="inscriptionMotDePasse"
                type="password"
                 />
                 <label htmlFor="inscriptionReMotDePasse">Confirmez le mot de passe</label>
                <input 
                required
                ref={addInputs}
                name="inscriptionReMotDePasse"
                id="inscriptionReMotDePasse"
                type="password"
                 />
                 <p id="messageErreur">{validation}</p>
                 <button id="inscriptionValidation" className="login-form-btn">S'inscrire</button>
            </form>

        </div>
    </>
    
  )
}
