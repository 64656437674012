import React, { useContext} from 'react'
import { Link } from "react-router-dom"
import { UserContext } from "../context/userContext";
import Deconnexion from './Deconnexion';
import '../css/footer.css'

export default function Footer() {
  const {currentUser} = useContext(UserContext)
  
  return (
    <>
      <div className='footer'>
        <p className='footerp'> Faire les CGU et tous le tralala</p>
      </div>
   
    
    </>
    
  )
}
