import React, { useContext, useRef, useState,useEffect } from "react";
import {auth, db} from "../firebase-config";
import { doc,collection, addDoc, getDocs, limitToLast, deleteDoc,updateDoc,query, where, orderBy } from "firebase/firestore"; 
import '../css/commentaire.css'
export default function Commentaire() {

const [className, setClassName] = useState('hidden');
const [validation, setValidation] = useState("");

const [add, setAdd] = useState([
  //{ id:1, Nom:"Bonsoir"}
]);
const formRef = useRef();
const formRef2 = useRef();
const inputs = useRef([]);
const inputs2 = useRef([]);

  const addInputs = (el) => {
    if (el && !inputs.current.includes(el)) {
      inputs.current.push(el);
    }
  };
  const addInputs2 = (el) => {
    if (el && !inputs2.current.includes(el)) {
      inputs2.current.push(el);
    }
  };
  const handleForm = async (e) => {
    e.preventDefault();
    //console.log(db);
    const nom = inputs.current[0].value;
    const prenom = inputs.current[1].value
    const adresse = inputs.current[2].value
    const commentaire = inputs.current[3].value
    try {
      const list = [];
      
      console.log("Document written with ID: ", await addDoc(collection(db, "Commentaires"), {
        Prenom: prenom,
        Nom: nom,
        Adresse: adresse,
        Commentaire:commentaire
      })
      );
      const querySnapshot = await getDocs(collection(db, "Commentaires"));
      querySnapshot.forEach((doc) => {
        const donnees = doc.data();
        const id = doc.id;
        
        list.push({
          donnees,
          id
        })
      });
      setAdd(list);

    } catch (e) {
      console.error("Error adding document: ", e);
    }
    
    console.log("this is add = "+add);
  };
  const Suppression = async (id) => {
    //e.preventDefault();
    console.log(id);
    try {
      console.log("Suppression");
      console.log (await deleteDoc(doc(db, "Commentaires", id)));
      //console.log(test2);
    }
    catch{
      //console.log(e)
    }
    
  }
  const Modification = async (item) => {
    //e.preventDefault();
    
    console.log(item);
    try {
      const commentaire = doc(db, "Commentaires", item.id);
      await updateDoc(commentaire, {
        Nom: "b"
      });
      
      //console.log(test2);
    }
    catch{
      //console.log(e)
    }
    
  }
  const rechercheCommentaire = async (e) =>{
    e.preventDefault();
    console.log("test");
    const list = [];
    try {
      const commentairesRef = collection(db, "Commentaires");
      //inputs2.current[0].value;
      const q = query(commentairesRef, where('Nom', '>=', inputs2.current[0].value , orderBy("Prenom","asc")));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const donnees = doc.data();
        const id = doc.id;
        
        list.push({
          donnees,
          id
        })
      });
      setAdd(list);
      //console.log(test2);
      setClassName(className === 'hidden' ? 'visible' : 'hidden');
    }
    catch{
      //console.log(e)
    }
  }
 
  return (
  <>
        <div>
            <form
            ref={formRef}
            onSubmit={handleForm}>
                <label htmlFor="connexionMail">Nom</label>
                <input 
                
                ref={addInputs}
                name="email"
                id="connexionMail"
                type="name"
                 />
                 <label htmlFor="connexionMail">Prénom</label>
                <input 
                required
                ref={addInputs}
                name="email"
                id="connexionMail"
                type="name"
                 />
                  <label htmlFor="connexionMail">Adresse</label>
                <input 
                
                ref={addInputs}
                name="email"
                id="connexionMail"
                type="name"
                 />
                 <label htmlFor="connexionMail">Commentaire</label>
                <input 
                required
                ref={addInputs}
                name="email"
                id="connexionMail"
                type="name"
                 />
                 <p id="messageErreur">{validation}</p>
                 <button id="connexionValidation">Publier</button>
            </form>
            <h1>Faire une recherche de commentaire </h1> 
            <form
            className="search-form"
            ref={formRef2}
            onSubmit={rechercheCommentaire}>
                <label htmlFor="connexionMail" className="label">Nom </label>
                <input 
                className="inputtextsmall"
                ref={addInputs2}
                name="email"
                id="connexionMail"
                type="name"
                 />
                 <label></label>
                 <label htmlFor="connexionMail" className="label"> Prénom </label>
                <input 
                className="inputtextsmall"
                ref={addInputs2}
                name="email"
                id="connexionMail"
                type="name"
                 />
                 
                  <label htmlFor="connexionMail" className="item-large-label">Adresse</label>
                <input 
                className="item-large-input"
                ref={addInputs2}
                name="email"
                id="connexionMail"
                type="name"
                 />
                 <p id="messageErreur" className="message-erreur">{validation}</p>
                 <button id="3" className="item-large-btn">Recherche</button>
            </form>
        <div className={className}>
          <table>
            <thead>
              <tr>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Adresse</th>
              <th>Commentaire</th>
              </tr>
            </thead>
            <tbody>
             <tr>
              
              </tr> 
            

            {
              add.length > 0 &&
               add.map(item => (
                console.log(item),
                <>
                  <tr key={item.id}>
                    <td>{item.donnees.Nom}</td>
                    <td>{item.donnees.Prenom}</td>
                    <td>{item.donnees.Adresse}</td>
                    <td>{item.donnees.Commentaire}</td>
                    <td><button onClick={()=> Modification(item)}>Modifier</button></td>
                    <td><button onClick={()=> Suppression(item.id)}>Supprimer</button></td>
                  </tr>
                </>
              ))
              }
              
              </tbody>
          </table>
        </div>
        </div>
    </>
  )
}
